<template>
    <div class="today-tolerance-widget">
        <div class="col-12 d-flex align-items-center justify-content-end">
            <b-form-select id="today-tolerance-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="requests"></b-form-select>
        </div>
        <div class="row mb-3">
            <div class="col-12" v-if="chartOptions.series.length > 0">
                <highcharts :options="chartOptions"></highcharts>
            </div>
        </div>
    </div>
</template>

<script>
import { format, compareAsc, subDays } from 'date-fns';
import DateRangePicker from 'vue-daterange-picker-light';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { map, clone, isEqual } from 'lodash';
import { exportCsv } from '@/lib/Exporting';
import chart from '@/widgets/highchart.vue'
import { objectEach, numberFormat } from 'highcharts';

export default {
    name: 'today-tolerance',
    data() {
        let date = new Date();

        let that = this;

        let exportingOptions = {
            filename: "test/ExecutionTolerance",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                    backgroundColor:"white"    
                },
                title: {text:"Execution Tolerance"}
            },
           
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        };

        return {
            type: 'Market',
            notation: 'Percentage',
            timePeriod: 'Daily',
             chartOptions: {
                exporting: exportingOptions,
                chart: {
                    type: 'bar',
                    height: 400
                },
                title: {
                    text: ''
                },
                xAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    }
                },
                yAxis: {
                    min: -0.1,
                    max: 0.1,
                    title: {
                        text: 'Tolerance'
                    },
                    labels: {
                        overflow: 'justify',
                        formatter: function() {
                            return this.value + '%';
                        }
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    valuePrefix: "",
                    formatter: function() {
                        let suffix = this.series.tooltipOptions.valueSuffix
                        let prefix = this.series.tooltipOptions.valuePrefix
                        return '<b>'+ this.series.name + '</b>: ' + prefix + numberFormat(this.y, 4) + suffix;
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                let suffix = this.series.tooltipOptions.valueSuffix
                                let prefix = this.series.tooltipOptions.valuePrefix
                                return '<b>' + prefix + numberFormat(this.y, 4) + suffix + '</b>';
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: []
            },
            columns: [],
            items: [],
            hasSetupListeners: false,
            allChartSeriesHidden: false,
            filterOptions: {
                type: [
                    {
                        value: 'Market',
                        text: 'Market'
                    },
                    {
                        value: 'Bucket',
                        text: 'Bucket'
                    },
                    {
                        value: 'Factor',
                        text: 'Factor'
                    }
                ]
            }
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        }
    },
    components: {
        DateRangePicker,
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('executiontolerance', this.onExecutionTolerance);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('executiontolerance', this.onExecutionTolerance);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            let now = new Date();

            let yesterday = subDays(now, 1);
            yesterday = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 17, 0, 0);
            let today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 0, 0);

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'ExecutionTolerance',
                args: {
                    startDate: format(yesterday, 'yyyy-MM-dd HH:mm:ss'),
                    endDate: format(today, 'yyyy-MM-dd HH:mm:ss'),
                    filterType: this.type,
                    notationType: this.notation,
                    reason: 'null',
                    timePeriod: this.timePeriod
                }
            });
        },
        onExecutionTolerance(event) {
            let dates = Object.keys(event.response).map(function(date) {
                return new Date(date);
            }).sort(compareAsc);

            if(dates.length > 0) {
                let latestDate = dates[dates.length - 1];

                let data = event.response[`${format(latestDate, 'yyyy-MM-dd', new Date())}T00:00:00`];

                let total = 0;

                let series = [];
                let keys = Object.keys(data);
                keys.sort();

                for(let key of keys) {
                    let value = parseFloat(data[key]) * 100;

                    series.push({
                        name: key,
                        data: [value],
                        color: getColor(key)
                    });

                    total += parseFloat(value);
                }

                if(series.length > 0) {
                    series.push({
                        name: 'Total',
                        data: [total],
                        color: getColor('Total')
                    });
                }

                if(!isEqual(this.chartOptions.series, series)) {
                    this.chartOptions.series = series;
                }
            }
        },
        sum(field) {
            return this.items.reduce(function(previousValue, item) {
                return previousValue + parseFloat(item[field]);
            }, 0.0);
        },
        onExport() {
            let headers = {};

            for(let column of this.columns) {
                headers[column.key] = column.label;
            }

            exportCsv(headers, map(this.items, clone), 'ExecutionTolerance');
        }
    }
}
</script>