<template>
    <div class="open-trades-widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
                <b-button v-b-modal.openTradesColumnsModal variant="light">
                    Columns
                </b-button>
            </div>
        </div>
        <b-table :items="filterItems()" :fields="getEnabledColumns()" responsive class="text-center table-details" show-empty sort-by="entryTime" :sort-desc="true"
            @row-clicked="onRowClick">
            <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <input type="text" v-model="filters[field.key]" placeholder="Search..." class="form-control" />
                </td>
            </template>
            <template slot="empty">
                No results found
            </template>
            <template v-slot:cell(estimatedExitTime)="data">
                {{data.value}}
                <!-- <vue-countdown :time="data.value" :transform="formatCountdown" v-if="data.value !== 'N/A'">
                    <template slot-scope="props">{{props.hours}}:{{props.minutes}}:{{props.seconds}}</template>
                </vue-countdown>
                <span v-else>{{data.value}}</span> -->
            </template>
        </b-table>
        <h1 id="tradeCount">{{tradeCount}} Open Trades</h1>
        <b-modal id="openTradesColumnsModal" title="Customise Columns" body-class="p-0" @show="setupColumnFiltersModal" @ok="finishColumnFilters" @hidden="resetColumnFilters">
            <draggable v-model="filteredColumns" :force-fallback="true" :fallback-tolerance="10">
                <div v-for="column in filteredColumns" :key="column.key" class="column-filter" @click="onColumnFilterClick($event, column)">
                    <div class="d-flex align-items-center justify-content-between">
                        <b-form-checkbox v-model="column.enabled" :value="true" :unchecked-value="false">
                            {{column.label}}
                        </b-form-checkbox>
                        <span class="drag-handle">
                            <i class="mdi mdi-menu"></i>
                        </span>
                    </div>
                </div>
            </draggable>
            <template v-slot:modal-footer="{ ok, cancel }">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-6 d-flex flex-row align-items-center justify-content-start">
                                <b-button variant="primary" @click="ok()">Confirm</b-button>
                                <b-button variant="secondary" @click="cancel()" class="ml-2">Cancel</b-button>
                            </div>
                            <div class="col-md-6 d-flex flex-row align-items-center justify-content-end">
                                <b-checkbox v-model="columnFiltersSave" :value="true" :unchecked-value="false">
                                    Save as preset
                                </b-checkbox>
                            </div>
                        </div>
                    </div>
                </template>
        </b-modal>
        <b-modal v-model="showTradeDetails" size="xl" title="Trade Details">
            <template v-if="!loadedTradeDetails">
                <p class="text-center">Loading trade details...</p>
            </template>
            <template v-else>
                <div class="row">
                    <div class="col-12 mt-3">
                        <h5>Executions</h5>
                        <b-table class="text-center" :items="tradeDetails.Executions" :fields="tradeDetailsColumns" sort-by="DateTime" responsive>
                            <template v-slot:cell(ToleranceTicks)="data">
                                <span v-if="parseFloat(data.value) < 0" style="color: red;">
                                    {{data.value}}
                                </span>
                                <span v-else>
                                    {{data.value}}
                                </span>
                            </template>
                        </b-table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-3">
                        <h5>Platform Speed</h5>
                        <chart v-if="chartOptions.series.length > 0" :chartOptions="chartOptions"></chart>
                    </div>
                </div>
            </template>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="primary" @click="cancel()" class="ml-auto">Close</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { map, clone } from 'lodash';
import { format, parse } from 'date-fns';
import { exportCsv } from '@/lib/Exporting';
import {convertToEST} from '@/lib/Helpers';
import VueCountdown from '@chenfengyuan/vue-countdown';
import chart from '@/widgets/highchart';

export default {
    name: 'open-trades',
    data() {
        let that = this;

        let columns = [];
        if(this.$store.state.auth.user.internal) {
            columns = [
                {
                    key: 'internalStrategy',
                    label: 'Internal Strategy',
                    sortable: true,
                    enabled: false,
                    tdClass: 'internalStrategyCell'
                },
                {
                    key: 'strategy',
                    label: 'Strategy',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'instrument',
                    label: 'Instrument',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'tradeDirection',
                    label: 'Position',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'size',
                    label: 'Size',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value, 0);
                    }
                },
                {
                    key: 'entryPrice',
                    label: 'Entry',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'currentPrice',
                    label: 'Current',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'returnBps',
                    label: 'Return (Bps)',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value);
                    }
                },
                {
                    key: 'fundReturn',
                    label: 'Return (%)',
                    sortable: true,
                    enabled: true,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'returnBpsSinceMarking',
                    label: 'Return (Bps) MTM',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value);
                    }
                },
                {
                    key: 'tickReturn',
                    label: 'Tick Return',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value, 0)
                    }
                },
                {
                    key: 'tickReturnSinceMarking',
                    label: 'Tick Return MTM',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value, 0)
                    }
                },
                {
                    key: 'tickSize',
                    label: 'Tick Size',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'returnOnCapital',
                    label: 'Return On Capital',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'hourlyTimeWeightedReturn',
                    label: 'Hourly Time Weighted Return',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value, 2, true));
                    }
                },
                {
                    key: 'volume',
                    label: 'Volume',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.currency(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'entryTime',
                    label: 'Entry Time',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return format(value, 'dd/MM/yyyy HH:mm:ss');
                    }
                },
                { //IMPORTANT ---------------------------------------------------------------------------------------------------------------------------------------
                    key: 'estimatedExitTime',//links to countdown Interval
                    label: 'Timed Exit',
                    sortable: true,
                    enabled: false, 
                    formatter: function(value) {
                        return format(value, 'dd/MM/yyyy HH:mm:ss');
                    },
                }//--------------------------------------------------------------------------------------------------------------------------------------------------  
        ]; }else {
            columns = [
                {
                    key: 'strategy',
                    label: 'Strategy',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'instrument',
                    label: 'Instrument',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'tradeDirection',
                    label: 'Position',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'size',
                    label: 'Size',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value, 0);
                    }
                },
                {
                    key: 'entryPrice',
                    label: 'Entry',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'returnBps',
                    label: 'Return (Bps)',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value);
                    }
                },
                {
                    key: 'fundReturn',
                    label: 'Return (%)',
                    sortable: true,
                    enabled: true,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'returnBpsSinceMarking',
                    label: 'Return (Bps) MTM',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value);
                    }
                },
                {
                    key: 'fundReturnSinceMarking',
                    label: 'Return (%) MTM',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'cashReturn',
                    label: 'Cash Return',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.currency(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'cashReturnSinceMarking',
                    label: 'Cash Return MTM',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.currency(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'tickReturn',
                    label: 'Tick Return',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        that.$options.filters.number(value, 0)
                    }
                },
                {
                    key: 'tickReturnSinceMarking',
                    label: 'Tick Return MTM',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        that.$options.filters.number(value, 0)
                    }
                },
                {
                    key: 'tickSize',
                    label: 'Tick Size',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'returnOnCapital',
                    label: 'Return On Capital',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'hourlyTimeWeightedReturn',
                    label: 'Hourly Time Weighted Return',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value, 2, true));
                    }
                },
                {
                    key: 'volume',
                    label: 'Volume',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.currency(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'entryTime',
                    label: 'Entry Time',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return format(value, 'dd/MM/yyyy HH:mm:ss');
                    }
                },
                {//In case of no info???? ------------------------------------------------------------------------------------------------------------------------------------
                    key: 'estimatedExitTime',
                    label: 'Timed Exit',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return format(value, 'dd/MM/yyyy HH:mm:ss');
                        //return that.$options.filters.DateTime(that.$options.filters.number(value));
                    }
                }
            ];
        }
        
        let sortedColumns = [];

        let loadedColumns = localStorage.getItem('openTradesColumns');
        if(loadedColumns !== null) {
            loadedColumns = JSON.parse(loadedColumns);

            for(let key in loadedColumns) {
                for(let column of columns) {
                    if(column.key === key) {
                        column.enabled = loadedColumns[key];
                        sortedColumns.push(column);
                        break;
                    }
                }
            }

            for(let column of columns) {
                if(!loadedColumns.hasOwnProperty(column.key)) {
                    sortedColumns.push(column);
                }
            }
        } else {
            for(let column of columns) {
                column.enabled = true;
            }

            sortedColumns = columns;
        }

        let filters = {};
        for(let column of sortedColumns) {
            filters[column.key] = '';
        }

        return {
            tradeCount: 0,
            countdownInterval:null,
            countdownExitDifference:0,
            columns: sortedColumns,
            items: [],
            filters: filters,
            columnFiltersSave: true,
            filteredColumns: [],
            hasSetupListeners: false,
            loadedTradeDetails: false,
            showTradeDetails: false,
            selectedTrade: {},
            tradeDetails: {},
            tradeDetailsColumns: [
                {
                    key: 'DateTime',
                    label: 'Timestamp',
                    sortable: true,
                    formatter: function(value) {
                        return format(value, 'dd/MM/yyyy HH:mm:ss');
                    }
                },
                {
                    key: 'Strategy',
                    label: 'Strategy',
                    sortable: true
                },
                {
                    key: 'Symbol',
                    label: 'Symbol',
                    sortable: true
                },
                {
                    key: 'TradeType',
                    label: 'TradeType',
                    sortable: true
                },
                {
                    key: 'RequestedSize',
                    label: 'Requested Size',
                    sortable: true
                },
                {
                    key: 'RequestedPrice',
                    label: 'Requested Price',
                    sortable: true
                },
                {
                    key: 'FilledSize',
                    label: 'Filled Size',
                    sortable: true
                },
                {
                    key: 'FilledPrice',
                    label: 'Filled Price',
                    sortable: true
                },
                {
                    key: 'ReturnBps',
                    label: 'Return (Bps)',
                    sortable: true,
                    formatter: function(value) {
                        return that.$options.filters.number(value);
                    }
                },
                {
                    key: 'fundReturn',
                    label: 'Return (%)',
                    sortable: true,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'ToleranceTicks',
                    label: 'Tolerance (Ticks)',
                    sortable: true,
                    formatter: function(value) {
                        return that.$options.filters.number(value);
                    }
                },
                {
                    key: 'TriggeredBy',
                    label: 'Triggered By',
                    sortable: true
                }
            ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 200
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: [
                        'Entry',
                        'Exit'
                    ]
                },
                yAxis: {
                    min: 0,
                    reversedStacks: false,
                    title: {
                        text: 'Platform Speed (ms)'
                    },
                    labels: {
                        style: {
                            whiteSpace: 'nowrap'
                        }
                    }
                },
                tooltip: {
                    valueDecimals: 0
                },
                legend: {
                    enabled: true
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        stacking: 'normal'
                    }
                },
                series: []
            }
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        }
    },
    components: {
        draggable,
        VueCountdown,
        chart
    },
    mounted() { //---------------------------------Countdown Fix---------------------------------------------------------------------------------------------------------
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('opentrades', this.onOpenTrades);
        window.clearInterval(this.countdownInterval);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('opentrades', this.onOpenTrades);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'OpenTrades',
                args: {}
            });
        },
        onOpenTrades(event) {
            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            if(event.request.accountName !== account) {
                return;
            }

            if(event.response.length > 0) {
                let date = new Date();

                let now = parse(event.response[0].currentTime, 'dd/MM/yyyy HH:mm:ss', date);
                for(let trade of event.response) {
                    trade.entryTime = parse(trade.entryTime, 'dd/MM/yyyy HH:mm:ss', date);

                        /* Here to format the row of the trade */
                   trade.estimatedExitTime = parse(trade.estimatedExitTime, 'dd/MM/yyyy HH:mm:ss', date) - now; 
                }
            }

            this.items = event.response;
            this.tradeCount = event.response.length;
        },
        getEnabledColumns() {
            let columns = [];

            for(let column of this.columns) {
                if(column.enabled) {
                    columns.push(column);
                }
            }

            return columns;
        },
        filterItems() {
            let filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key => String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()));
            });

            if(filtered.length > 0) {
                return filtered;
            } else {
                let emptyFilters = {};
                for(let key in this.filters) {
                    emptyFilters[key] = '';
                }

                return [];
            }
        },
        onColumnFilterClick(event, column) {
            let tagName = event.target.tagName.toLowerCase();

            if(tagName === 'input' || tagName === 'label') {
                return;
            }

            column.enabled = !column.enabled;
        },
        setupColumnFiltersModal() {
            this.filteredColumns = map(this.columns, clone);
        },
        finishColumnFilters() {
            this.columns = map(this.filteredColumns, clone);

            if(this.columnFiltersSave) {
                let columns = {};
                for(let column of this.columns) {
                    columns[column.key] = column.enabled;
                }

                localStorage.setItem('openTradesColumns', JSON.stringify(columns));
            }
        },
        resetColumnFilters() {
            this.filteredColumns = [];
        },
        onRowClick(item) {
            this.showTradeDetails = !this.showTradeDetails;
            if(this.showTradeDetails) {
                this.loadedTradeDetails = false;

                this.selectedTrade = item;
                this.$store.commit('setSelectedTrade', item);

                let account = this.account;
                if(!account) {
                    account = this.$store.state.activeAccount;
                }

                this.$ws.once('tradedetails', this.onTradeDetails);
                this.$ws.send({
                    accountName: account,
                    request: 'TradeDetails',
                    args: {
                        tradeID: item.id,
                        accountName: item.account
                    }
                });
            }
        },
        onTradeDetails(event) {
            let executions = [];
            for(let execution of event.response.Executions) {
                execution.DateTime = new Date(execution.DateTime);

                if(execution.FilledPrice != 0 && execution.FilledSize != 0) {
                    executions.push(execution);
                }
            }

            event.response.Executions = executions;

            this.tradeDetails = event.response;

            if(!this.loadedTradeDetails) {
                this.loadedTradeDetails = true;
            }

            this.chartOptions.series = [];

            let totals = {};
            for(let key in event.response.PlatformTiming) {
                let series = {
                    name: key,
                    data: []
                };

                for(let component in event.response.PlatformTiming[key]) {
                    let value = parseFloat(event.response.PlatformTiming[key][component]);

                    series.data.push({
                        name: component,
                        y: value
                    });

                    if(!totals.hasOwnProperty(component)) {
                        totals[component] = 0;
                    }

                    totals[component] += value;
                }

                this.chartOptions.series.push(series);
            }

            console.log(totals);

            let max = 100;

            let total = 0;
            for(let key in totals) {
                if(totals[key] > total) {
                    total = totals[key];
                }
            }

            let ceilValue = Math.ceil(total);

            if(ceilValue > max) {
                max = ceilValue;
            } else if(ceilValue === max) {
                max += 10;
            }

            if(max !== this.chartOptions.yAxis.max) {
                this.chartOptions.yAxis.max = max;
            }
        },
        getPriceChartInterval() {
            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            if(this.$store.getters.isHftAccount(account)) {
                return '1';
            }

            return '30';
        },
        onExport() {
            let headers = {};

            for(let column of this.columns) {
                headers[column.key] = column.label;
            }

            let items = map(this.items, clone);
            for(let item of items) {
                item.entryTime = format(item.entryTime, 'yyyy-MM-dd HH:mm:ss');
            }

            exportCsv(headers, items, 'OpenTrades');
        },
        formatCountdown(props) {//Making sure Estimated exits is in the right format
            Object.entries(props).forEach(([key, value]) => {
                // Adds leading zero
                const digits = value < 10 ? `0${value}` : value;
                props[key] = digits; 
            });

            return props;
         }
    }
}
</script>

<style lang="scss" scoped>
#tradeCount{
    font-size: 1rem;
    color: gray
}
</style>