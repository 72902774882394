<template>
    <div id="page-wrapper">
        <top-header></top-header>
        <side-navbar></side-navbar>
        <main-content title="Dashboard">
            <div class="row d-flex align-items-stretch" style="margin-bottom: 20px;">
                <div class="col-md-6 mb-0">
                    <b-card no-body class="tabbed-card h-100">
                        <b-tabs card class="h-100">
                            <b-tab title="Return Breakdown (Total)" active @click="breakdownsResizeUpdate()" :key="breakdownsResize + 'breakdowns'">
                                <breakdowns :showTable="false" latest></breakdowns>
                            </b-tab>
                            <b-tab title="Tolerance" @click="toleranceResizeUpdate()" :key="toleranceResize + 'tolerance'">
                                <TodayTolerance ></TodayTolerance>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </div>
                <div class="col-md-6 mb-0">
                    <b-card no-body class="tabbed-card h-100">
                        <b-tabs card class="h-100">
                            <b-tab title="Leverage (Floating)" active @click="leverageResizeUpdate()" :key="leverageResize + 'leverage'">
                                <open-leverages></open-leverages>
                            </b-tab>
                            <b-tab title="Margin (Floating)" @click="marginResizeUpdate()" :key="marginResize + 'margin'">
                                <open-margins></open-margins>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b-card no-body class="tabbed-card">
                        <b-tabs card>
                            <b-tab title="Open Trades" active>
                                <open-trades></open-trades>
                            </b-tab>
                            <b-tab title="Closed Trades">
                                <closed-trades></closed-trades>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </div>
            </div>
        </main-content>
    </div>
</template>

<script>
import TopHeader from '@/components/TopHeader'; 
import SideNavbar from '@/components/SideNavbar';
import MainContent from '@/components/MainContent';
import TodayReturn from '@/widgets/TodayReturn';
import GrowthSummary from '@/widgets/GrowthSummary';
import Breakdowns from '@/widgets/Breakdowns';
import OpenLeverages from '@/widgets/OpenLeverages';
import OpenMargins from '@/widgets/OpenMargins';
import OpenTrades from '@/widgets/OpenTrades';
import ClosedTrades from '@/widgets/ClosedTrades';
import TodayTolerance from '@/widgets/TodayTolerance';

export default {
    components: {
        TopHeader,
        SideNavbar,
        MainContent,
        TodayReturn,
        GrowthSummary,
        Breakdowns,
        OpenLeverages,
        OpenMargins,
        OpenTrades,
        ClosedTrades,
        TodayTolerance
    },
    data: function() {
        return {
        toleranceResize:false,
        breakdownsResize:false,
        marginResize:false,
        leverageResize:true
        }
    },
    methods:{
        toleranceResizeUpdate(){
            this.toleranceResize = !this.toleranceResize;
        },
        breakdownsResizeUpdate(){
            this.breakdownsResize = !this.breakdownsResize;
        },
        leverageResizeUpdate(){
            this.leverageResize = !this.leverageResize;
        },
        marginResizeUpdate(){
            this.marginResize = !this.marginResize;
        }
    }
}
</script>

<style lang="scss">
.tabs.h-100 {
    .tab-content {
        height: calc(100% - 31.250px);
    }
}
</style>