var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"closed-trades-widget"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 d-flex align-items-center justify-content-end"},[_c('b-button',{staticClass:"mr-auto",attrs:{"variant":"light"},on:{"click":_vm.onExport}},[_vm._v(" Export ")]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.closedTradesColumnsModal",modifiers:{"closedTradesColumnsModal":true}}],attrs:{"variant":"light"}},[_vm._v(" Columns ")])],1)]),_c('b-table',{staticClass:"text-center table-details",attrs:{"items":_vm.filterItems(),"fields":_vm.getEnabledColumns(),"responsive":"","show-empty":"","sort-by":"exitTime","sort-desc":true},on:{"row-clicked":_vm.onRowClick},scopedSlots:_vm._u([{key:"top-row",fn:function(ref){
var fields = ref.fields;
return _vm._l((fields),function(field){return _c('td',{key:field.key},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters[field.key]),expression:"filters[field.key]"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search..."},domProps:{"value":(_vm.filters[field.key])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filters, field.key, $event.target.value)}}})])})}}])},[_c('template',{slot:"empty"},[_vm._v(" No results found ")])],2),_c('h1',{attrs:{"id":"tradeCount"}},[_vm._v(_vm._s(_vm.tradeCount)+" Closed Trades")]),_c('b-modal',{attrs:{"id":"closedTradesColumnsModal","title":"Customise Columns","body-class":"p-0"},on:{"show":_vm.setupColumnFiltersModal,"ok":_vm.finishColumnFilters,"hidden":_vm.resetColumnFilters},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 d-flex flex-row align-items-center justify-content-start"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return ok()}}},[_vm._v("Confirm")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v("Cancel")])],1),_c('div',{staticClass:"col-md-6 d-flex flex-row align-items-center justify-content-end"},[_c('b-checkbox',{attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.columnFiltersSave),callback:function ($$v) {_vm.columnFiltersSave=$$v},expression:"columnFiltersSave"}},[_vm._v(" Save as preset ")])],1)])])]}}])},[_c('draggable',{attrs:{"force-fallback":true,"fallback-tolerance":10},model:{value:(_vm.filteredColumns),callback:function ($$v) {_vm.filteredColumns=$$v},expression:"filteredColumns"}},_vm._l((_vm.filteredColumns),function(column){return _c('div',{key:column.key,staticClass:"column-filter",on:{"click":function($event){return _vm.onColumnFilterClick($event, column)}}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-form-checkbox',{attrs:{"value":true,"unchecked-value":false},model:{value:(column.enabled),callback:function ($$v) {_vm.$set(column, "enabled", $$v)},expression:"column.enabled"}},[_vm._v(" "+_vm._s(column.label)+" ")]),_c('span',{staticClass:"drag-handle"},[_c('i',{staticClass:"mdi mdi-menu"})])],1)])}),0)],1),_c('b-modal',{attrs:{"size":"xl","title":"Trade Details"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"primary"},on:{"click":function($event){return cancel()}}},[_vm._v("Close")])]}}]),model:{value:(_vm.showTradeDetails),callback:function ($$v) {_vm.showTradeDetails=$$v},expression:"showTradeDetails"}},[(!_vm.loadedTradeDetails)?[_c('p',{staticClass:"text-center"},[_vm._v("Loading trade details...")])]:[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-3"},[_c('h5',[_vm._v("Executions")]),_c('b-table',{staticClass:"text-center",attrs:{"items":_vm.tradeDetails.Executions,"fields":_vm.tradeDetailsColumns,"sort-by":"DateTime","responsive":""},scopedSlots:_vm._u([{key:"cell(ToleranceTicks)",fn:function(data){return [(parseFloat(data.value) < 0)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('span',[_vm._v(" "+_vm._s(data.value)+" ")])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-3"},[_c('h5',[_vm._v("Platform Speed")]),(_vm.chartOptions.series.length > 0)?_c('chart',{attrs:{"chartOptions":_vm.chartOptions}}):_vm._e()],1)])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }