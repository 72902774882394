<template>
    <div class="growth-summary-widget">
        <highcharts :options="chartOptions" :style="`height: ${height};`"></highcharts>
        <div class="table-responsive" v-if="showTable">
            <table class="table table-sm">
                <tr>
                    <td>Yesterday</td>
                    <td class="text-right">{{tableOptions.data.yesterdaypercent | number | percentage}} {{tableOptions.data.yesterdaycash | number | currency(true)}}</td>
                </tr>
                <tr>
                    <td>This Week</td>
                    <td class="text-right">{{tableOptions.data.weekpercent | number | percentage}} {{tableOptions.data.weekcash | number | currency(true)}}</td>
                </tr>
                <tr>
                    <td>This Month</td>
                    <td class="text-right">{{tableOptions.data.monthpercent | number | percentage}} {{tableOptions.data.monthcash | number | currency(true)}}</td>
                </tr>
                <tr>
                    <td>This Year</td>
                    <td class="text-right">{{tableOptions.data.yearpercent | number | percentage}} {{tableOptions.data.yearcash | number | currency(true)}}</td>
                </tr>
                <tr>
                    <td>Drawdown</td>
                    <td class="text-right">{{tableOptions.data.drawdown | number | percentage}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { numberFormat } from 'highcharts';

export default {
    name: 'growth-summary',
    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    }
                },
                yAxis: {
                    min: -5,
                    max: 5,
                    title: {
                        text: 'Return'
                    },
                    labels: {
                        overflow: 'justify',
                        formatter: function () {
                            return this.value + '%';
                        }
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    formatter: function () {
                        return '<b>' + this.series.name + '</b>: ' + numberFormat(this.y, 2) + '%';
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return '<b>' + numberFormat(this.y, 2) + '%</b>'
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: []
            },
            tableOptions: {
                data: {
                    yesterdaycash: 0,
                    yesterdaypercent: 0,
                    weekcash: 0,
                    weekpercent: 0,
                    monthcash: 0,
                    monthpercent: 0,
                    yearcash: 0,
                    yearpercent: 0,
                    drawdown: 0
                }
            },
            branding: {
                colors: {
                    Yesterday: 'rgb(179, 219, 177)',
                    Week: 'rgb(165, 165, 165)',
                    Month: 'rgb(88, 109, 119)',
                    Year: 'rgb(88, 109, 119)'
                }
            },
            hasSetupListeners: false
        };
    },
    props: {
        height: {
            type: String,
            default: '300px'
        },
        showTable: {
            type: Boolean,
            default: true
        },
        account: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('growthsummary', this.onGrowthSummary);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('growthsummary', this.onGrowthSummary);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'GrowthSummary',
                args: {}
            });

            if(this.showTable) {
                this.$ws.on('drawdown', this.onDrawdown);
            
                this.$ws.send({
                    sessionID: 'NotImplemented',
                    accountName: account,
                    request: 'drawdown',
                    args: {}
                });
            }
        },
        onGrowthSummary(event) {
            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            if(event.request.accountName !== account) {
                return;
            }

            let response = event.response;

            let keys = [
                'yesterdayPercent',
                'yesterdayCash',
                'weekPercent',
                'weekCash',
                'monthPercent',
                'monthCash',
                'yearPercent',
                'yearCash',
            ];

            let min = -5;
            let max = 5;
            
            var series = [];
            for(var key of keys) {
                let value = parseFloat(response[key]) * 100;

                if(key !== 'yearPercent' && key.indexOf('Cash') === -1) {
                    let name = key.substring(0, key.length - 7);
                    name = name.charAt(0).toUpperCase() + name.slice(1);
                    series.push({
                        name: name,
                        data: [value],
                        color: this.branding.colors[name]
                    });

                    let ceilValue = Math.ceil(value);
                    let floorValue = Math.floor(value);

                    if(ceilValue > max) {
                        max = ceilValue;
                    } else if(ceilValue === max) {
                        max += 1;
                    }

                    if(floorValue < min) {
                        min = floorValue;
                    } else if(floorValue === min) {
                        min -= 1;
                    }

                    if(max !== this.chartOptions.yAxis.max) {
                        this.chartOptions.yAxis.max = max;
                    }

                    if(min !== this.chartOptions.yAxis.min) {
                        this.chartOptions.yAxis.min = min;
                    }
                }

                if(key.indexOf('Cash') !== -1) {
                    this.tableOptions.data[key.toLowerCase()] = parseFloat(value) / 100;
                } else {
                    this.tableOptions.data[key.toLowerCase()] = value;
                }
            }

            this.chartOptions.series = series;
        },
        onDrawdown(event) {
            this.tableOptions.data.drawdown = parseFloat(event.response) * 100;
        }
    }
}
</script>