<template>
    <div class="today-return-widget">
        <highcharts :options="chartOptions" :style="`height: ${height};`"></highcharts>
        <div class="table-responsive" v-if="showTable">
            <table class="table table-sm">
                <tbody>
                    <tr>
                        <td class="text-left">Total</td>
                        <td style="width: 1px; white-space: nowrap;" class="text-right">{{tableOptions.data.total.return | number | percentage}}</td>
                        <td style="width: 1px; white-space: nowrap;" class="text-right">{{tableOptions.data.total.cash | number | currency(true)}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Floating</td>
                        <td style="width: 1px; white-space: nowrap;" class="text-right">{{tableOptions.data.floating.return | number | percentage}}</td>
                        <td style="width: 1px; white-space: nowrap;" class="text-right">{{tableOptions.data.floating.cash | number | currency(true)}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Booked</td>
                        <td style="width: 1px; white-space: nowrap;" class="text-right">{{tableOptions.data.booked.return | number | percentage}}</td>
                        <td style="width: 1px; white-space: nowrap;" class="text-right">{{tableOptions.data.booked.cash | number | currency(true)}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Leverage</td>
                        <td style="width: 1px; white-space: nowrap;" class="text-right" colspan="2">{{tableOptions.data.leverage | number}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Margin</td>
                        <td style="width: 1px; white-space: nowrap;" class="text-right" colspan="2">{{tableOptions.data.margin | number(2, true) | percentage}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { numberFormat } from 'highcharts';

export default {
    name: 'today-return',
    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    }
                },
                yAxis: {
                    min: -2,
                    max: 2,
                    title: {
                        text: 'Return'
                    },
                    labels: {
                        overflow: 'justify',
                        formatter: function() {
                            return this.value + '%';
                        }
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    formatter: function() {
                        return '<b>' + this.series.name + '</b>: ' + numberFormat(this.y, 2) + '%';
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return '<b>' + numberFormat(this.y, 2) + '%</b>';
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: []
            },
            tableOptions: {
                data: {
                    total: {
                        return: 0,
                        cash: 0
                    },
                    floating: {
                        return: 0,
                        cash: 0
                    },
                    booked: {
                        return: 0,
                        cash: 0
                    },
                    leverage: 0,
                    margin: 0
                }
            },
            branding: {
                colors: {
                    Total: 'rgb(179, 219, 177)',
                    Floating: 'rgb(165, 165, 165)',
                    Booked: 'rgb(88, 109, 119)'
                }
            },
            hasSetupListeners: false
        };
    },
    props: {
        height: {
            type: String,
            default: '300px'
        },
        showTable: {
            type: Boolean,
            default: true
        },
        account: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('todayreturn', this.onTodayReturn);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('todayreturn', this.onTodayReturn);
                this.$ws.on('openleverage', this.onOpenLeverage);
                this.$ws.on('openmargin', this.onOpenMargin);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                accountName: account,
                request: 'TodayReturn',
                args: {}
            });

            this.$ws.send({
                accountName: account,
                request: 'OpenLeverage',
                args: {
                    filterType: 'Market'
                }
            });
            this.$ws.send({
                accountName: account,
                request: 'OpenMargin',
                args: {
                    filterType: 'Market'
                }
            });
        },
        onTodayReturn(event) {
            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            if(event.request.accountName !== account) {
                return;
            }

            let response = event.response;

            let min = -2;
            let max = 2;

            let series = [];
            for(var key in response.return) {
                let value = parseFloat(response.return[key]);

                series.push({
                    name: key,
                    data: [value],
                    color: this.branding.colors[key]
                });
                
                let ceilValue = Math.ceil(value);
                let floorValue = Math.floor(value);

                if(ceilValue > max) {
                    max = ceilValue;
                } else if(ceilValue === max) {
                    max += 1;
                }

                if(floorValue < min) {
                    min = floorValue;
                } else if(floorValue === min) {
                    min -= 1;
                }
            }

            if(max !== this.chartOptions.yAxis.max) {
                this.chartOptions.yAxis.max = max;
            }

            if(min !== this.chartOptions.yAxis.min) {
                this.chartOptions.yAxis.min = min;
            }

            this.tableOptions.data.total.return = response.return.Total;
            this.tableOptions.data.total.cash = response.net.Total;

            this.tableOptions.data.floating.return = response.return.Floating;
            this.tableOptions.data.floating.cash = response.net.Floating;

            this.tableOptions.data.booked.return = response.return.Booked;
            this.tableOptions.data.booked.cash = response.net.Booked;

            this.chartOptions.series = series;
        },
        onOpenLeverage(event) {
            let sum = 0;
            for(let key in event.response) {
                sum += Math.abs(event.response[key]);
            }

            this.tableOptions.data.leverage = sum;
        },
        onOpenMargin(event) {
            let sum = 0;
            for(let key in event.response) {
                sum += event.response[key];
            }

            this.tableOptions.data.margin = sum;
        }
    }
}
</script>